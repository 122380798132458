import domReady from '@wordpress/dom-ready';
import $ from 'jquery';

domReady(() => {
  const items = $('.sidebar-list__item');
  const mainToggle = $('.sidebar__heading__toggle');
  const leftMenu = $('.sidebar');
  const mainContent = $('#sidebar-list');

  if (mainToggle) {
    mainToggle.on('click', function () {
      if (leftMenu.hasClass('opened')) {
        leftMenu.removeClass('opened');
        mainContent.slideUp();
        mainToggle.attr('aria-expanded', 'false');
      } else {
        leftMenu.addClass('opened');
        mainContent.slideDown();
        mainToggle.attr('aria-expanded', 'true');
      }
    });
  }

  if (items) {
    items.each(function () {
      const item = $(this);
      const toggle = item.find('.sidebar-list__item__toggle');
      const content = item.find('.sidebar-list__item__children');

      if (item.hasClass('opened')) {
        content.slideDown();
        toggle.attr('aria-expanded', 'true');
      }

      toggle.on('click', function () {
        if (item.hasClass('opened')) {
          content.slideUp();
          item.removeClass('opened');
          toggle.attr('aria-expanded', 'false');
        } else {
          content.slideDown();
          item.addClass('opened');
          toggle.attr('aria-expanded', 'true');
        }
      });
    });
  }
});
